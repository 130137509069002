<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="box">
      <Title title="媒介订单列表"/>

      <div class="ct_box">
        <orderNav ref="orderNav" num="order_num" @nav_tab="nav_tab" :nav_id="mediaOrder.category_id"/>
        <div class="search_status_box flex">
          <div class="search_box flex">
            <order_time @get_time="get_time"/>
            <order_search @search_text="search_text"/>
            <medium_search @searchMedium="searchMedium"/>
          </div>
          <orderStatus ref="orderstatus_tab" type="order_status_num" @status_tab="status_tab"
                       :status_list="status_list" :category_id="mediaOrder.category_id"
                       :order_status="mediaOrder.order_status"/>
        </div>
        <table class="table">
          <thead>
          <tr>
            <td>订单号</td>
            <td>标题</td>
            <!-- <td>媒介类别</td> -->
            <td>媒介名称</td>
            <td v-if="mediaOrder.category_id == 2 || mediaOrder.category_id == 7">
              所属平台
            </td>
            <td>金额</td>
            <td>状态</td>
            <td>溢价</td>
            <td>创建时间</td>
            <td>发布时间</td>
            <td>撤稿时间</td>
            <td>撤稿原因</td>
            <td>操作</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in tableData" :key="item.id">
            <td>
              {{ item.sn }}
              <timeLimit :limitNum="item.limit_time"/>
            </td>
            <td>
              <td_remark :text="item.title"/>
              <span class="look" @click="lookShow(item.id)">[查看]</span>
            </td>
            <!-- <td>{{ item.category_title }}</td> -->
            <td>
              <td_remark :text="item.medium_title"/>
            </td>
            <td v-if="mediaOrder.category_id == 2 || mediaOrder.category_id == 7">
              {{ item.portal_title }}
            </td>
            <td>￥{{ item.order_price }}</td>
            <td :style="{
		color: item.order_status == 0 ? '#000' : item.order_status == 1 ? '#FF8400' : item.order_status == 2 ?
			'#00B052' : item.order_status == 3 ? '#FF8400' : '#FF0000'
	}">
              {{
                item.order_status == 0 ? '未处理' : item.order_status == 1 ? '发布中' : item.order_status
                == 2
                    ? '已出稿' : item.order_status == 3 ? '申请退款' : item.order_status == 4 ? '拒绝退款' :
                        item.order_status == 5 ? '撤稿' : item.order_status == 6 ? '申请撤稿' : item.order_status == 7
                            ?
                            '拒稿' : item.order_status == 8 ? '退稿' : '拒绝撤稿'
              }}
              <!-- {{ statusList[item.order_status + 1].title }} -->

              <span v-show="item.order_status == 0 || item.order_status == 1"
                    style="color: #FF0000;">{{
                  item.is_limit == 1 ? '[已超时]' : ''
                }}</span>
              <td_remark v-if="item.reason4.reason" :text="'原因:' + item.reason4.reason"/>
            </td>
            <td>
              <media_premium_button :list="item" @get_list="get_list"/>
            </td>
            <td>{{ item.createtime }}</td>
            <td>{{ item.publish_time ? item.publish_time : '暂无' }}</td>
            <td>
              {{ item.withdraw_time ? item.withdraw_time : '暂无' }}
            </td>
            <td>
              <td_remark v-show="item.reason4 != '' && item.reason == ''" :text="item.reason4.id"/>
              <td_remark v-show="item.reason4 != '' && item.reason == ''"
                         :text="item.reason4.reason"/>
              <td_remark v-show="item.reason != '' && item.reason.id != '请选择'"
                         :text="item.reason.id"/>
              <td_remark v-show="item.reason != ''" :text="item.reason.reason"/>
            </td>
            <td class="operation">
              <span
                  v-if="item.order_status == 2 || (item.order_status == 3 && item.order_url != '') || (item.order_status == 4 && item.order_url != '')"
                  @click="LinkShow(item)">查看链接</span>
              <span
                  v-if="!item.is_timeout && (item.order_status == 0 && item.is_limit) || (item.order_status == 1 && item.is_limit) || item.order_status == 0"
                  @click="withdraw(item)">撤稿</span>
              <!-- <span v-if="item.order_status == 0" @click="orderStatus(item, 5)">撤稿</span> -->

              <span
                  v-if="!item.is_timeout && ((item.order_status == 1 && !item.is_limit) || (item.order_status == 2 && !item.is_limit))"
                  @click="orderStatus(item, 3)">申请退款</span>

              <!-- <span @click="redispatch_fn(item)" v-if="item.order_status == 2">再发布</span> -->
              <span @click="orderShow(item)"
                    v-if="item.order_status == 2 && !item.is_timeout">提交工单</span>
              <span
                  v-if="(item.order_status == 4 || item.order_status == 9 || item.order_status == 7) && !item.is_timeout"
                  @click="serviceShow = true">联系客服</span>
              <span @click="cancel(item)"
                    v-if="item.order_status == 3 && !item.is_timeout">取消退款</span>
              <span v-if="item.order_status == 0 && !item.is_timeout"
                    @click="goEdit(item)">修改稿件</span>
              <span @click="issue(item)"
                    v-if="item.order_status == 2 || item.order_status == 5 || item.order_status == 8">重新发布</span>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :total_order_price="total_order_price" :count="count"
                    :total_page="total_page"/>
      </div>

    </div>
    <order_link_poup :linkList="linkList" :linkShow="linkShow" @close="linkShow = false"/>
    <el-dialog title="提交工单" :append-to-body="true" :visible.sync="order" :show-close="false">
      <p class="workOrder_title">请描述您的问题</p>
      <el-input type="textarea" :rows="6" resize="none" v-model="work">
      </el-input>
      <div class="flex" style="margin-top: 20px; justify-content: flex-end">
        <button class="copy_but" @click="orderUp">确认提交</button>
      </div>
    </el-dialog>

    <el-dialog title="联系客服" :append-to-body="true" :visible.sync="serviceShow" :show-close="false">
      <p class="workOrder_title">客服电话：83730126/82821214</p>

    </el-dialog>

    <media_rejection_poup :order_type="popupObj.type === false ? popupObj.type : ''" :himt="popupObj.himt"
                          :order_status="order_status" :placeholder="popupObj.placeholder"
                          :reason_title="popupObj.title"
                          :title="popupObj.title" :rejectShow="rejectShow" :rejectItem="rejectItem"
                          @rejectComfirm="rejectComfirm"
                          @rejectClose="rejectShow = false"/>

  </div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import media_rejection_poup from '@/components/media_rejection_poup'
import timeLimit from '@/components/timeLimit'
import {timeCut} from '@/util/timeCut'
import {mapMutations, mapState} from 'vuex'
import orderNav from '@/components/orderNav'
import medium_search from '@/components/medium_search'
import orderStatus from '@/components/orderStatus'
import order_link_poup from '@/components/order_link_poup'
import media_premium_button from '@/components/media_premium_button'
import order_time from '@/components/order_time'
import order_search from '@/components/order_search'
import router from "@/router";

export default {
  components: {
    Title,
    timeLimit,
    order_time,
    media_rejection_poup,
    orderNav,
    medium_search,
    orderStatus,
    order_link_poup,
    media_premium_button,
    order_search

  },
  computed: {
    ...mapState(['mediaOrder', 'orderId']),
    ...mapMutations('mediaOrder', ['MEDIAORDER_TAB'])
  },
  watch: {
    tableData: {
      handler(newVal) {
        this.$refs.orderNav.getMediaNav()
        this.$refs.orderstatus_tab.getNum(this.mediaOrder.category_id)
      }
    }
  },
  mounted() {
    if (this.$route.query.time) {
      this.mediaOrder.order_status = 6
    }
    if (this.$route.query.status && this.$route.query.status == '退稿') {
      this.status = {
        id: 5,
        title: '撤稿'
      }
    }
    if (this.$route.query.status == 2) {
      this.mediaOrder.order_status = this.$route.query.status
    }
    console.log(this.$store.state.mediaOrder);
    this.get_list()
  },
  data() {
    return {
      timer: null,
      time: '',
      title: '',
      tableData: [],
      linkShow: false, //查看链接弹窗
      order: false, //提交工单弹窗
      orderItem: {}, //工单信息
      rejectItem: {},
      rejectShow: false,
      serviceShow: false, //客服
      work: '',
      total_page: 0, //分页总页数
      count: 0, //总条数
      total_order_price: 0, //当前页数所有条数价钱总和
      page: 1,
      medium_name: '',
      linkList: {},
      fullscreenLoading: false, //loding
      order_status: '',
      popupObj: {
        himt: '',
        title: '',
        reason_title: '',
        placeholder: '',

      },
      status_list: [
        {
          id: '',
          title: '全部',
        },
        {
          id: 0,
          title: '未处理',
        },
        {
          id: 1,
          title: '发布中',
        },
        {
          id: 2,
          title: '已出稿',
        },
        {
          id: 3,
          title: '申请退款',
        },
        {
          id: 4,
          title: '拒绝退款',
        },
        {
          id: 5,
          title: '撤稿',
        },
        {
          id: 6,
          title: '限时订单',
        },
      ],

    }
  },
  methods: {
    search_text(text) {
      this.title = text
      console.log(text);
      this.pageChange(1)
    },
    status_tab(id) {
      this.$store.commit('MEDIAORDER_TAB', id)
      this.pageChange(1)
    },

    searchMedium(title) {
      console.log(title);
      this.medium_name = title
      this.pageChange(1)
    },
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    nav_tab(id) {
      this.$store.commit('SETCATEGORYID', id)
      this.pageChange(1)

    },
    LinkShow(item) {
      this.linkList = item
      this.linkShow = true

    },
    get_time(time) {
      this.time = time || []
      this.pageChange(1)
    },
    rejectComfirm() {
      this.rejectShow = false
      this.get_list()
    },
    // 提交工单
    orderShow(item) {
      this.order = true
      this.orderItem = item
      console.log(this.orderItem);
    },
    // 提交
    orderUp() {
      if (this.work != '') {
        this.curlPost('/api/users/work/add', {
          order_id: this.orderItem.id,
          desc: this.work
        }).then(res => {
          console.log(res);
          if (res.data.code) {
            this.get_list()
            this.work = ''
            this.order = false
            this.$message({
              message: '提交成功',
              type: 'success',
              duration: 800
            })

            setTimeout(() => {
              this.$store.commit('SETACTIVEMENU', '/user/MyOrder')
              this.$router.push('/user/MyOrder')
            }, 1000)


          } else {
            return this.$message({
              message: '提交失败',
              type: 'warning',
            })
          }
        })
      }
    },
    lookShow(item) {
      // this.$router.push({
      // 	path: '/user/lookdetails',
      // 	query: {
      // 		id: item
      // 	}
      // })
      const {href} = this.$router.resolve({
        name: 'lookdetails',
        query: {
          id: item
        }
      })
      window.open(href, '_blank',)
    },
    issue(item) {
      this.$confirm('是否重新发布?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then((res) => {
        this.$store.commit('SETACTIVEMENU', '/user/SetMenu/fill?id=' + this.mediaOrder.category_id)
        this.$router.push({
          path: '/user/SetMenu/fill',
          query: {
            id: this.mediaOrder.category_id,
            order_id: item.id
          },
        })

      })
    },
    // 申请退款  撤稿要求
    orderStatus(item, status) {
      this.rejectItem = JSON.parse(JSON.stringify(item))
      this.order_status = status
      this.rejectShow = true
      this.popupObj = {
        himt: '',
        title: '',
        placeholder: '',

      }
      if (status == 3) {
        this.popupObj.type = false
        this.popupObj.himt = '提示：此稿件确定要申请退款吗?'
        this.popupObj.title = '申请退款'
        this.popupObj.placeholder = '请输入退款原因'
        this.reason_title = '退款原因'
      } else {
        this.popupObj.himt = '提示：此稿件确定要撤稿吗?'
        this.popupObj.title = '撤稿'
        this.popupObj.placeholder = '请输入撤稿原因'
        this.reason_title = '撤稿原因'
      }
    },

    // 状态为未处理的稿件走不需要提交input的接口
    withdraw(item) {
      this.$confirm('是否撤稿?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/order/edit', {
          id: item.id,
          status: 5,
          url: item.order_url,
        }).then(res => {

          if (res.data.code) {
            this.get_list()
            this.$message({
              type: 'success',
              message: '已撤稿'
            });
          }
        })
      })
    },
    // 编辑稿件
    goEdit(item) {
      console.log(typeof (item.category_id));
      switch (item.category_id) {
        case 1:
          this.$store.commit('childTab', '资讯门户发布')
          this.$router.push({
            path: '/user/edit_fill?id=' + item.id,
          })
          break
        case 2:
          this.$store.commit('childTab', '自媒体发布')
          this.$router.push({
            path: '/user/edit_fill?id=' + item.id,
          })
          break
        case 3:
          this.$store.commit('childTab', '纸媒介发布')
          this.$router.push({
            path: '/user/edit_fill?id=' + item.id,
          })
          break
        case 4:
          this.$store.commit('childTab', '公众号发布')
          this.$router.push({
            path: '/user/edit_fill?id=' + item.id,
          })
          break
        case 5:
          this.$store.commit('childTab', '微博发布')
          this.$router.push({
            path: '/user/edit_fill?id=' + item.id,
          })
          break
        case 6:
          this.$store.commit('childTab', '小红书发布')
          this.$router.push({
            path: '/user/edit_fill?id=' + item.id,
          })
          break
        case 7:
          this.$store.commit('childTab', '短视频发布')
          this.$router.push({
            path: '/user/edit_fill?id=' + item.id,
          })
          break

        default:
          break;
      }
      // this.$router.push({
      // 	path: '/user/edit_fill',
      // 	query: {
      // 		id: item.id
      // 	}
      // })
    },
    // 取消退款
    cancel(item) {
      console.log(item);
      this.$confirm('是否取消退款?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/order/edit', {
          id: item.id,
          status: 1,
          url: item.order_url,
        }).then(res => {
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '待发布 '
            });
            this.get_list()
          }
        })
      })
    },
    // 再发布
    redispatch_fn(item) {
      console.log(item);
      switch (item.category_id) {
        case 1:
          this.$store.commit('childTab', '资讯门户发布')
          this.$router.push({
            path: '/user/or_fill?id=' + item.id,
          })
          break
        case 2:
          this.$store.commit('childTab', '自媒体发布')
          this.$router.push({
            path: '/user/or_fill?id=' + item.id,
          })
          break
        case 3:
          this.$store.commit('childTab', '纸媒介发布')
          this.$router.push({
            path: '/user/or_fill?id=' + item.id,
          })
          break
        case 4:
          this.$store.commit('childTab', '公众号发布')
          this.$router.push({
            path: '/user/or_fill?id=' + item.id,
          })
          break
        case '5':
          this.$store.commit('childTab', '微博发布')
          this.$router.push({
            path: '/user/edit_fill?id=' + item.id,
          })
          break
        case '6':
          this.$store.commit('childTab', '小红书发布')
          this.$router.push({
            path: '/user/or_fill?id=' + item.id,
          })
          break
        case '7':
          this.$store.commit('childTab', '短视频发布')
          this.$router.push({
            path: '/user/or_fill?id=' + item.id,
          })
          break

        default:
          break;
      }
    },
    get_list() {
      this.fullscreenLoading = true
      let data = {}
      data.page = this.page
      data.limit = 11
      data.category_id = this.mediaOrder.category_id
      if (this.mediaOrder.order_status !== '') {
        data.order_status = this.mediaOrder.order_status
      }
      if (this.title != '') {
        data.title = this.title
      }
      if (this.medium_name != '') {
        data.medium_name = this.medium_name
      }
      if (this.time.length) {
        data.start_time = this.time[0] / 1000
        data.end_tiem = this.time[1] / 1000
      }

      this.curlGet('/api/users/order/list', data).then(res => {
        if (res.data.code) {
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData = res.data.data.list
          if (data.order_status == 2) {
            this.total_order_price = res.data.data.total_order_price
          } else {
            this.total_order_price = 0
          }
          this.tableData.forEach((item, index) => {
            if (this.tableData[index].withdraw_time) {
              this.tableData[index].withdraw_time = timeCut(this.tableData[index].withdraw_time * 1000)
            }
            this.tableData[index].createtime = timeCut(this.tableData[index].createtime * 1000)
            if (this.tableData[index].publish_time) {
              this.tableData[index].publish_time = timeCut(this.tableData[index].publish_time * 1000)
            }
          })
          this.fullscreenLoading = false
        }
      })
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
}

</script>

<style lang="scss" scoped>
@import '@/scss/MediaOrder';
@import '@/scss/order_work';
</style>